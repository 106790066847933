import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from './pages/Home';
import SingleNews from './pages/SingleNews';
import Result from './pages/Result';
import Gallery from './pages/Gallery';

const Routes = () => (
    <BrowserRouter basename="/">
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/noticia/:slug" component={SingleNews} />

            <Route exact path="/images/:slice" component={Gallery} />

            <Route exact path="/all" component={Result} />
            <Route exact path="/:param/:value" component={Result} />
        </Switch>
    </BrowserRouter>
    
)

export default Routes;