import React from 'react';
import { Col, Row } from 'react-bootstrap';

import './styles.css';

export default class Footer extends React.Component {
    render() {
      return (
        <footer>
            <div className="container">
                <Row>
                    <Col>
                        <div className="boxFlex">
                            <div className="item">
                                <p>&copy; COPYRIGHT 2021. JBS.</p>
                            </div>
                            <div className="item">
                                <b>MEDIA ROOM</b>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </footer>
      );
    }
}  
