import React from 'react';
import Slider from "react-slick";
import moment from 'moment';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { Col, Row } from 'react-bootstrap';

import Select from 'react-select';

import News from '../News';

import './styles.css';

import { base, lang } from '../../services/api';

export default class Region extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      leftNews: [],
      sliderNews: [],
      rightNews: [],
      options: [],
      region: '',
      label: lang === 'br' ? 'Selecione...' : 'Choose...'
    };
  }

  componentDidMount() {
    this.setNews();
    this.setOptions();
  }

  setOptions = () => {
    let options = [{ value: '', label: lang === 'br' ? 'Selecione...' : 'Choose...' }];

    if(this.props.slices[4]){
      if(this.props.slices[4].cards.length > 0){
        this.props.slices[4].cards.map(card => {
          options.push({ value: card, label: card });
        });
      }
    }

    this.setState({ options });
  }

  setNews = () => {
    let leftNews = [];
    let sliderNews = [];
    let rightNews = [];
    let news = this.props.news;

    if(this.state.region !== ''){
      news = this.props.news.filter(n => n.cards.includes(this.state.region));
    }

    if(news.length > 0){
      if(news.length > 3){
        news.map(n => {
          if(n.pics.length > 0 && sliderNews.length < 3){
            sliderNews.push(n);
          } else if (n.pics.length > 0 && leftNews.length < 4) {
            leftNews.push(n);
          } else if(rightNews.length < 4) {
            rightNews.push(n);
          }
        });
      } else {
        news.map(n => {
          if(n.pics.length > 0 && rightNews.length < 4){
            rightNews.push(n);
          } else if (n.pics.length > 0 && sliderNews.length < 3) {
            sliderNews.push(n);
          } else if(leftNews.length < 4){
            leftNews.push(n);
          }
        });
      }
      
    }

    this.setState({ leftNews, sliderNews, rightNews });
  }

  handleRegionChange = (option) => {
    this.setState({ region: option.value, label: option.label }, () => { this.setNews(); });
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      arrows: false
    };

    return (
      <div id="region">
        <Row>
            <Col sm={12}>
                <ul className="titleCategories">
                  <li>{this.props.slices[4]?.name}</li>
                </ul>
                <Select options={this.state.options} value={{value: this.state.region, label: this.state.label}} onChange={(option) => this.handleRegionChange(option)} />
                <a className="show-all" href={`/slice/${this.props.slices[4]?.name}`}>{lang === 'br' ? 'Ver mais' : 'View All'}</a>
            </Col>
        </Row>
        <div className="rowSlicePosts">
          {this.state.leftNews.length > 0 &&
            <div className="colPosts">
              {this.state.leftNews.map((l,i) => (
                <News 
                  key={i}
                  title={l.title} 
                  desc={l.description}
                  cards={l.cards}
                  slug={l.slug}
                />
              ))}
            </div>
          }
          {this.state.sliderNews.length > 0 &&
            <div className="colSlider">
              <Slider {...settings} className="sliderRight">
                {this.state.sliderNews.map((s,i) => (
                  <div key={i} className="boxSlider">
                    <a href={`/noticia/${s.slug}`}>
                      <img src={s.pics.length > 0 ? `${s.pics[0].pic_v}` : ''} alt={s.title} />
                    </a>
                    <div className="newsContent">
                        <div className="infoBox">
                            <ul>
                                {/* <li>{moment(s.date).format('DD/MM/YY')}</li> */}
                                <li><a href={`/card/${s.tags.length > 0 ?? s.tags[0]}`}>{s.tags.length > 0 ?? s.tags[0]}</a></li>
                            </ul>
                        </div>
                        <a href={`/noticia/${s.slug}`}>
                            <h2 className="newsTitle">{s.title}</h2>
                        </a>
                        <h3 className="newsDesc">{s.description}</h3>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          }
          {this.state.rightNews.length > 0 &&
            <div className="colThumbs">
              {this.state.rightNews.map((r,i) => (
                <News 
                  key={i}
                  pics={r.pics} 
                  title={r.title} 
                  desc={r.description}
                  cards={r.cards}
                  slug={r.slug}
                />
              ))}
            </div>
          }
        </div>
      </div>
    );
  }
}  