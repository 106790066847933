import React from 'react';
import { Modal, Button, Col, Container, Row } from 'react-bootstrap';

import { AiOutlineGlobal } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';

import { useLocation, withRouter } from 'react-router';

import './styles.css';

import logo from '../../assets/images/logo.png';

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      showContactMenu: false,
      term: '',
      menu: false,
      submenu: false,
      lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'br',
      showLang: false,
    };
  }

  componentDidMount() {
    this.setProvisionalLang();
    document.addEventListener('scroll', this.handleScroll);
  }
  
  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  setProvisionalLang = () => {
    let url = window.location.href;
    if(!url.includes('imaxinformatica') && !url.includes('aaaaa')){
      localStorage.setItem('lang',  'br');
      this.setState({ lang: 'br', showLang: false });
    } else {
      this.setState({ showLang: true });
    }
  }

  handleOpen = (e) => {
    e.preventDefault();
    this.setState({ showContactMenu: true });
  }

  handleClose = () => {
    this.setState({ showContactMenu: false });
  }

  handleScroll = (e) => {
    let header = document.querySelector('header');
    let hasFixed = header.classList.contains('fixed');

    if(window.scrollY > 36){
      if(!hasFixed){
        header.classList.add('fixed');
      }
    } else {
      if(hasFixed){
        header.classList.remove('fixed');
      }
    }
  }

  search = () => {
    window.location.href=`/search/${this.state.term}`;
  }

  handleShowMenu = () => {
    let menu = this.state.menu ? false : true;
    this.setState({ menu });
    let b = document.querySelector('body');
    if(menu){
      b.style.overflow = 'hidden';
    } else {
      b.style.overflow = 'auto';
    }
  }

  handleShowSubmenu = (e) => {
    e.preventDefault();
    let submenu = this.state.submenu ? false : true;
    this.setState({ submenu });
  }
  
  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.search();
    }
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.search();
  }

  handleLang = (l) => {
    let current = this.state.lang;
    if(current !== l){
      localStorage.setItem('lang', l);
      window.location.reload();
    }
  }

  render() {
    return (
      <>
        <Modal show={this.state.showContactMenu} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Contato</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Entre em contato conosco, prioritariamente, pelo e-mail <b>jbs@fsb.com.br.</b></p>
          </Modal.Body>
        </Modal>
        <div id="top">
          <Container>
            <h1>MEDIA ROOM</h1>
            {this.state.showLang &&
              <div id="langBox">
                <AiOutlineGlobal style={{color: '#FFF', fontSize: 20, marginTop: '8px'}} />
                <button className={`langButton${this.state.lang === 'br' ? ' active' : ''}`} onClick={() => this.handleLang('br')}>Português</button>
                <button className={`langButton${this.state.lang === 'us' ? ' active' : ''}`} onClick={() => this.handleLang('us')}>English</button>
              </div>
            }
          </Container>
        </div>
        <header>
          <div id="headerBox" className="container">
            <button className="openMenu" onClick={() => this.handleShowMenu()}>
              <div className="rowToggle"></div>
              <div className="rowToggle"></div>
              <div className="rowToggle"></div>
            </button>
            <a href="https://jbs.com.br">
              <img src={logo} alt="JBS" id="logo"/>
            </a>
            <div id="searchBox">
              <input type="text" onKeyDown={this.handleKeyDown} onChange={(e) => this.setState({ term: e.target.value})} placeholder={this.state.lang === 'br' ? 'Digite a notícia que procura' : 'Search'} />
              <a href="#" onClick={this.handleSearch}><BiSearch /></a>
            </div>
            <ul id="mainMenu" className={this.state.menu ? 'show' : 'hide'}>
              {this.props.location.pathname !== '/' &&
                <li><a href="/">{this.props.menus[0]?.name}</a></li>
              }
              <li>
                <a href="/" onClick={this.handleShowSubmenu}>{this.props.menus[1]?.name}</a>
                <div id="mainSubmenu" className={this.state.submenu ? 'show' : 'hide'}>
                  <ul>
                    <li className="mainSubmenuTitle"><a href="#">{this.props.slices[4]?.name}</a></li>
                    {this.props.slices[4]?.cards && this.props.slices[4].cards.map((card, index) => (
                      <li key={index}><a href={`/card/${card}`}>{card}</a></li>
                    ))}
                  </ul>
                  <ul>
                    <li className="mainSubmenuTitle"><a href="#">{this.props.slices[5]?.name}</a></li>
                    {this.props.slices[4]?.cards && this.props.slices[5].cards.map((card, index) => (
                      <li key={index}><a href={`/card/${card}`}>{card}</a></li>
                    ))}
                  </ul>
                  <ul>
                    <li className="mainSubmenuTitle"><a href="#">{this.state.lang === 'br' ? 'Outros assuntos' : 'More topics'}</a></li>
                    <li><a href={`/slice/${this.props.slices[1].name}`}>{this.props.slices[1].name}</a></li>
                    <li><a href={`/slice/${this.props.slices[2].name}`}>{this.props.slices[2].name}</a></li>
                  </ul>
                  <ul className="mainSubmenuMore">
                    <li className="mainSubmenuTitle"><a href="/all">{this.state.lang === 'br' ? 'Listar todas' : 'All Articles'}</a></li>
                  </ul>
                </div>
              </li>
              <li><a href="#" onClick={(e) => this.handleOpen(e)}>{this.props.menus[2]?.name}</a></li>
            </ul>
          </div>
        </header>
      </>
    );
  }
}  

export default withRouter(Header);