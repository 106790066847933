import React from 'react';
import moment from 'moment';

import './styles.css';

import newsTwo from '../../assets/images/newsTwo.jpg';

import { base } from '../../services/api';

export const News = (props) => {

  let hasImage = false;

  if(props.pics){
    if(props.pics.length > 0){
      hasImage = true;
    }
  }

  const Image = (props) => {
    if(hasImage){
      return (
        <a href={`/noticia/${props.slug}`}>
          <img src={`${props.pics[0].pic_v}`} alt={props.title} className="newsImg" />
        </a>
      );
    }

    return '';
  }

  return (
    <div className="newsBox">
      <Image pics={props.pics} slug={props.slug} title={props.title} />
      <div className={`newsContent${hasImage ? ' hasImage' : ''}`}>
        {props.date &&
          <span className="newsDate">{props.date}</span>
        }
        <a href={props.slug ? `/noticia/${props.slug}` : ''}>
          <h2 className="newsTitle">{props.order ? `${props.order}. ` : ''}{props.title ? props.title : 'Friboi participa de feira supermercadista em Santa Catarina'}</h2>
        </a>
        <h3 className="newsDesc">{props.desc ? props.desc : props.title}</h3>
        <div className="infoBox">
          <ul>
            {props.tags && !props.manyTags &&
              <li><a href={`/tag/${props.tags[0]}`}>{props.tags[0]}</a></li>
            }
            {props.tags && props.manyTags &&
              <>
                {props.tags.map(tag => (
                  <li><a href={`/tag/${tag}`}>{tag}</a></li>
                ))}
              </>
            }
            {props.cards &&
              <li><a href={`/card/${props.cards[0]}`}>{props.cards[0]}</a></li>
            }
          </ul>
        </div>
      </div>
    </div>
  );
} 

export default News;